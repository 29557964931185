const MIXPANEL_PAGE_VIEW = {
    ARTICLE: "age-times:view:article",
    CATEGORY: "age-times:view:category",
    SUB_CATEGORY: "age-times:view:sub-category"
}

const MIXPANEL_SCROLL = {
    ARTICLE: "age-times:scroll",
}

const MIXPANEL_CLICK_EVENT = {
    AFFILIATE: "age-times:click:affiliate"
}

const MIXPANEL_SUBSCRIBE_FLOW = {
    SUBSCRIBE_POPUP: 'age-times:subscribe-popup',
    SUBSCRIBE_POPUP_CLOSE: "age-times:subscribe-popup:close",
    SUBSCRIBE_POPUP_BACKDROP: "age-times:subscribe-popup:close",
    SUBSCRIBE_POPUP_SUBMIT: "age-times:subscribe-popup:submit",
    SUBSCRIBE_POPUP_CANCEL: "age-times:subscribe-popup:cancel",
    SUBSCRIBE_POPUP_HEADER_OPEN: "age-times:subscribe-popup-header:open",
    SUBSCRIBE_POPUP_FOOTER_OPEN: "age-times:subscribe-popup-footer:open",
}

const MIXPANEL_LOGIN_FLOW = {
    PROTECTION_OVERLAY_POPUP: "age-times:login:protection-overlay-popup",
    LOGIN_POPUP: "age-times:login:popup",
    LOGIN_SKIPPED: "age-times:login:skipped",
    CONNECT_CLICK: "age-times:login:click-connect",
    LOGIN_SUCCESS: "age-times:login:success",
    LOGOUT: "age-times:logout"
}

const TRACK = {MIXPANEL_PAGE_VIEW, MIXPANEL_CLICK_EVENT, MIXPANEL_SCROLL, MIXPANEL_SUBSCRIBE_FLOW, MIXPANEL_LOGIN_FLOW}

Object.freeze(MIXPANEL_PAGE_VIEW);
Object.freeze(MIXPANEL_CLICK_EVENT);
Object.freeze(MIXPANEL_SUBSCRIBE_FLOW);
Object.freeze(MIXPANEL_LOGIN_FLOW);
Object.freeze(TRACK);

export default TRACK;
